import * as React from 'react';

export const Ph5: React.FC = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="119.725"
    height="356.65"
    viewBox="0 0 119.725 356.65"
  >
    <defs>
      <style>{`.a{fill:#eb9e6b;}.b{fill:#2f2e41;}.c{fill:#3f3d56;}`}</style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(16.209 146.136)">
        <path
          className="a"
          d="M528.065,618.937,527.4,632.86l11.271,1.989V620.926Z"
          transform="translate(-469.498 -458.11)"
        />
        <path
          className="a"
          d="M431.04,618.937l.663,13.923-11.271,1.989V620.926Z"
          transform="translate(-407.479 -458.11)"
        />
        <path
          className="b"
          d="M544.7,424s-8.538,2.036-10.565,17.9-.194,38.264,2.458,45.557c0,0-2.463,11.46-1.137,14.776s2.463,6.63,1.137,7.956-1.989,41.105,0,44.42,3.777,32.926,3.777,32.926,12.8,4.2,15.451-3.09-1.359-31.081,1.293-33.07S559.6,519.9,559.6,519.9s-1.989-1.527,0-2.853,4.393-3.535,2.4-6.85-3.315-1.326,0-2.652c1.847-.716-.23-4.256,1.451-5.3l8.151-37.63s3.483,47.575,4.809,50.227,1.989,1.748,0,5.063-.663,4.527,0,6.516.345,22.234,3,28.2c1.548,3.449,6.2,29.309,7.3,32.926,0,0,12.408,3.315,13.734,0s-1.989-21.663,0-22.326,0-7.057,0-12.361,2.829-32.955,2.829-32.955-1.989-3.737-.663-7.052.663-2.652,0-5.3-1.326-1.8,0-3.79,0-11.331,0-11.331,3.911-33.777,3.138-50.516-8.395-15.752-8.395-15.752Z"
          transform="translate(-530.019 -424.002)"
        />
        <path
          className="b"
          d="M639.2,722.458s-1.989-1.989-2.652,0-.663,20.553-.663,20.553,5.3,3.978,5.3,5.967,3.978,8.619,13.26,7.293l.275-.042a8.778,8.778,0,0,0,6.527-12.425l-9.454-20.021S645.164,717.154,639.2,722.458Z"
          transform="translate(-579.968 -545.931)"
        />
        <path
          className="b"
          d="M529.419,722.458s1.989-1.989,2.652,0,.663,20.553.663,20.553-5.3,3.978-5.3,5.967-3.978,8.619-13.26,7.293l-.276-.042a8.778,8.778,0,0,1-6.527-12.425l9.454-20.021S523.452,717.154,529.419,722.458Z"
          transform="translate(-506.522 -545.931)"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M602.749,171.473s5.068,12.037-6.336,17.739,30.41,16.472,34.845,2.534c0,0-11.4-4.435-6.336-17.739Z"
          transform="translate(-550.362 -130.545)"
        />
        <circle
          className="a"
          cx="17.739"
          cy="17.739"
          r="17.739"
          transform="translate(47.001 12.735)"
        />
        <path
          className="b"
          d="M602.708,95.26l-3.169-1.269s6.627-7.3,15.848-6.661l-2.593-2.855s6.339-2.538,12.1,4.124c3.029,3.5,6.534,7.618,8.719,12.255h3.394l-1.417,3.119,4.958,3.119-5.089-.56a17.261,17.261,0,0,1-.481,8.079l.136,2.465s-5.9-9.127-5.9-10.4v3.172s-3.169-2.855-3.169-4.758l-1.729,2.221-.864-3.489-10.661,3.489,1.729-2.855-6.627.952,2.593-3.489s-7.491,4.124-7.78,7.613-4.034,7.93-4.034,7.93l-1.729-3.172S594.353,100.018,602.708,95.26Z"
          transform="translate(-551.488 -84)"
        />
        <path
          className="c"
          d="M582.078,213.239s-10.77-.391-14.255-4.947c0,0-22.491,7.482-25.025,13.817s8.87,34.845,8.87,34.845-19.64,58.287-10.137,58.287,31.044,7.6,43.715,3.168a162.858,162.858,0,0,1,24.075-6.335s1.267-17.106,0-20.274-4.435-1.267-2.534-5.7,1.267-5.068.634-6.969,1.267-4.435,1.267-6.969-.634-8.236-.634-8.236,17.106-39.914,13.3-43.715-23.151-11.126-23.151-11.126S592.849,215.773,582.078,213.239Z"
          transform="translate(-520.822 -149.394)"
        />
        <path
          className="a"
          d="M1.7,0s-5.7,29.777,3.8,27.243S10.566,0,10.566,0Z"
          transform="translate(0.661 180.472) rotate(-22)"
        />
        <path
          className="c"
          d="M546.792,236.5l-23.013,5.465c-1.776.888-9.248,9.686-9.614,11.637l-7.211,30.6v6.181c0,.634,1.267,1.236,0,3.137s-3.327,1.9-2.06,3.168c.723.657-.092,4.054,0,5.027v54.938s8.7-3.168,11.868,0c0,0,2.37-33.628,4.27-36.8s0-8.584,0-8.584-3.168-.953,0-4.12,3.38-7.607,2.747-8.241,0-4.758,0-5.392S539.569,253.6,539.569,253.6Z"
          transform="translate(-504.497 -175.346)"
        />
        <path
          className="a"
          d="M1.7,27.4S-4.006-2.381,5.5.153,10.566,27.4,10.566,27.4Z"
          transform="translate(103.327 205.873) rotate(-158)"
        />
        <path
          className="c"
          d="M504.5,236.851l19.31,5.2c1.776.888,6.482,11.069,6.847,13.02l9.977,29.214s2.965,5.017,2.965,5.651-1.267,1.766,0,3.666,3.644,2.2,2.377,3.465c-.723.657.092,3.517,0,4.49v4.49l-6.867,49.919s-6.6-4.753-9.773-1.585c0,0,3.221-22.188,1.32-25.356s0-17.7,0-17.7,3.168-2.115,0-5.282c-2.1-2.1-1.194-4.1-1.32-6.075-.047-.647-2.78-5.737-2.78-6.371s-18.537-39.914-18.537-39.914Z"
          transform="translate(-426.61 -175.436)"
        />
      </g>
    </g>
  </svg>
);
