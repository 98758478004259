import { css } from '@emotion/react';
import { listChildAnimation, theme } from '@web/features/theme';
import { motion } from 'framer-motion';
import { wellnessSteps } from './wellness-steps';

/**
 * # WellnessPagination
 *
 * This component serves as dots on the bottom of the wellness check to
 *  show a user where they are in the flow.
 */
export function WellnessPagination({ currentStep }: { currentStep: number }) {
  return (
    <motion.div
      variants={listChildAnimation}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '2rem auto',
      }}
    >
      {wellnessSteps.map((_, idx) => (
        <svg
          key={_.title}
          height="25"
          width="40"
          css={css`
            cursor: pointer;
            color: ${theme.colors.blackAlpha[800]};
            transition: color 150ms ease;
          `}
        >
          <rect
            height="8"
            width="32"
            rx="6"
            css={css`
              fill: ${currentStep === idx
                ? theme.colors.primary
                : theme.colors.whiteAlpha[800]};

              transition: fill 500ms ease;
            `}
          />
        </svg>
      ))}
    </motion.div>
  );
}
