import * as React from 'react';

export const SvgWellnessHeart: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="255.221" height="216.93">
    <defs>
      <style>{`.b{fill:#f01123}.c{fill:#ababab}.d{fill:#634480}`}</style>
    </defs>

    <g opacity=".999">
      <path
        d="M122.06 16.74a38.966 38.966 0 0167.718 8.993 29.833 29.833 0 011.911 15.772c-1.642 9.42-8.635 16.875-15.364 23.669-17.653 17.818-35.7 35.251-53.437 52.435L74.255 75.1c-6.621-5.789-13.38-11.74-17.623-19.445-9.4-17.063-2.772-41.067 14.051-50.889S111.663.971 122.06 16.74z"
        className="b"
      />
      <path
        d="M202.156 117.156c2.713-6.717 6.734-10.245 12.015-13.543 8.173-5.107 12.977-10.906 18.485-17.248 1.992-2.293 3.927-4.962 2.091-7.379-1.274-1.676-4.969-2.083-7.048-1.76-10.366 1.617-16.048 10.827-24.57 16.947-8.018 5.757-16.327 6.915-25.377 10.857s-16.436 7.751-23.844 17.465c-5.606 7.351-6.638 19.4-7.3 28.625-1.555 21.758-2.318 43.657-1.787 65.464 15.177 0 27.742.775 42.81 0a210.494 210.494 0 01-4.475-23.059c-1.326-11.4-3.11-23.076-3.873-34.528a25.008 25.008 0 01.983-9c1.188-4.113 5.907-7.21 9.136-10.017 11.656-10.131 27.895-15.582 35.594-28.971 5.6-9.746 5.781-11.96 9.864-17.744 2.1-2.979 4.619-5.7 6.231-8.968 1.627-3.3 2.5-7.252 3.868-10.669 2.353-5.855 6.561-10.388 9.294-16.075.82-1.706 1.607-4.527.163-5.75-1.358-1.148-5.152-.419-6.7.447-9.1 5.085-12.108 12.814-18.09 23.466 3.357.516 3.469.447 5.907 2.165 1.854 1.306 1.348 3.207.467 5.3s-3.646 4.567-5.152 6.265a111.157 111.157 0 01-11.773 11.79c-1.612 1.378-3.184 2.367-4.9 3.614a52.15 52.15 0 00-6.589 5.268c-2.626 2.832-3.667 5.273-5.43 7.038z"
        className="c"
      />
      <path
        d="M214.2 83.517l3.024-7.216c.642-1.533 1.286-3.071 1.812-4.648.647-1.938 1.113-3.932 1.58-5.922l1.664-7.109c1.19-5.083 2.4-10.21 4.661-14.913a16.78 16.78 0 012.925-4.461 8.166 8.166 0 014.616-2.52c1.842-.259 3.908 2.7 2.612 6.379-.165.474-1.511 4.631-1.772 5.87-.8 3.814-1.365 8.119-2.17 11.933-.427 2.024-1.18 7.472-1.34 8.376-.454 2.585-2.4 4.629-3.439 7.04a12.209 12.209 0 00-5.554.943 11.976 11.976 0 00-3.8 2.192 262.913 262.913 0 00-4.819 4.056z"
        className="c"
      />
      <path
        d="M204.113 92.234a27.334 27.334 0 011.486-9.1c2.449-8.146 6.031-15.957 7.821-24.271.74-3.431 1.432-7.84 2.9-11.029a9.171 9.171 0 017.46-5.631 1.856 1.856 0 011.743.469 2.121 2.121 0 01-.022 1.955 113.33 113.33 0 00-4.55 16.13c-.269 1.19-1.412 6.867-1.735 8.042a27.575 27.575 0 01-1.711 4.152 62.561 62.561 0 00-3.515 9.576 5.42 5.42 0 01-.57 1.491 7.37 7.37 0 01-1.77 1.6 30.15 30.15 0 00-3.419 3.733 9.186 9.186 0 01-4.118 2.883z"
        className="c"
      />
      <path
        d="M193.792 98.178c-.2-2.841 1.422-5.441 2.661-8.006 3.1-6.426 3.989-13.661 4.87-20.743a16.646 16.646 0 011.424-5.734 5.661 5.661 0 014.629-3.256 4.93 4.93 0 011.516.225c1.15.343.792 2.261.523 3.054-.341.995-.131 6.759-.773 8.267a78.846 78.846 0 00-5.609 21.459 61.874 61.874 0 00-9.241 4.734z"
        className="c"
      />
      <g>
        <path
          d="M53.064 117.157c-2.713-6.717-6.734-10.245-12.015-13.543-8.173-5.107-12.977-10.906-18.485-17.248-1.992-2.293-3.927-4.962-2.091-7.379 1.274-1.676 4.969-2.083 7.048-1.76 10.366 1.617 16.048 10.827 24.57 16.947 8.018 5.757 16.327 6.915 25.377 10.857s16.436 7.751 23.844 17.465c5.606 7.351 6.638 19.4 7.3 28.625 1.555 21.758 2.318 43.657 1.787 65.464-15.177 0-27.742.775-42.81 0a210.53 210.53 0 004.476-23.059c1.326-11.4 3.11-23.076 3.873-34.528a25.006 25.006 0 00-.983-9c-1.187-4.113-5.907-7.21-9.136-10.017-11.657-10.131-27.895-15.582-35.594-28.971-5.6-9.746-5.781-11.96-9.864-17.744-2.1-2.979-4.619-5.7-6.231-8.968-1.627-3.3-2.5-7.252-3.868-10.669C7.909 67.774 3.701 63.241.968 57.554c-.82-1.706-1.607-4.527-.163-5.75 1.358-1.148 5.152-.419 6.7.447 9.1 5.085 12.108 12.814 18.09 23.466-3.357.516-3.468.447-5.907 2.165-1.854 1.306-1.348 3.207-.467 5.3s3.646 4.567 5.152 6.265a111.153 111.153 0 0011.773 11.79c1.612 1.378 3.184 2.367 4.9 3.614a52.149 52.149 0 016.589 5.268c2.625 2.832 3.667 5.273 5.429 7.038z"
          className="c"
        />
        <path
          d="M41.021 83.518l-3.024-7.216c-.642-1.533-1.286-3.071-1.812-4.648-.647-1.938-1.113-3.932-1.58-5.922l-1.664-7.109c-1.19-5.083-2.4-10.21-4.661-14.913a16.782 16.782 0 00-2.925-4.461 8.166 8.166 0 00-4.616-2.52c-1.842-.259-3.908 2.7-2.612 6.379.165.474 1.511 4.631 1.772 5.87.8 3.814 1.365 8.119 2.17 11.933.427 2.024 1.18 7.472 1.34 8.376.454 2.585 2.4 4.629 3.439 7.04a12.21 12.21 0 015.554.943 11.975 11.975 0 013.8 2.192 262.913 262.913 0 014.819 4.056z"
          className="c"
        />
        <path
          d="M51.109 92.235a27.336 27.336 0 00-1.486-9.1c-2.449-8.146-6.031-15.957-7.82-24.271-.741-3.431-1.432-7.84-2.9-11.029a9.171 9.171 0 00-7.46-5.631 1.855 1.855 0 00-1.743.469 2.121 2.121 0 00.022 1.955 113.31 113.31 0 014.55 16.13c.269 1.19 1.412 6.867 1.735 8.042a27.561 27.561 0 001.711 4.152 62.561 62.561 0 013.515 9.576 5.422 5.422 0 00.57 1.491 7.368 7.368 0 001.77 1.6 30.145 30.145 0 013.419 3.733 9.186 9.186 0 004.117 2.883zM61.43 98.179c.2-2.841-1.422-5.441-2.661-8.006-3.1-6.426-3.989-13.661-4.871-20.743a16.646 16.646 0 00-1.424-5.734 5.662 5.662 0 00-4.629-3.256 4.929 4.929 0 00-1.516.225c-1.15.343-.792 2.261-.523 3.054.341.995.131 6.759.773 8.267a78.844 78.844 0 015.609 21.459 61.874 61.874 0 019.242 4.734z"
          className="c"
        />
        <path
          d="M122.061 16.74a38.966 38.966 0 0167.718 8.993 29.833 29.833 0 011.911 15.772c-1.642 9.42-8.635 16.875-15.364 23.669-17.653 17.818-35.7 35.251-53.437 52.435L74.256 75.1c-6.621-5.789-13.38-11.74-17.623-19.445-9.4-17.063-2.772-41.067 14.051-50.889s40.98-3.795 51.377 11.974z"
          className="b"
        />
        <g>
          <path
            d="M202.157 117.157c2.713-6.717 6.734-10.245 12.015-13.543 8.173-5.107 12.977-10.906 18.485-17.248 1.992-2.293 3.927-4.962 2.091-7.379-1.274-1.676-4.969-2.083-7.048-1.76-10.366 1.617-16.048 10.827-24.57 16.947-8.018 5.757-16.327 6.915-25.377 10.857s-16.436 7.751-23.844 17.465c-5.606 7.351-6.638 19.4-7.3 28.625-1.555 21.758-2.318 43.657-1.787 65.464 15.177 0 27.742.775 42.81 0a210.494 210.494 0 01-4.475-23.059c-1.326-11.4-3.11-23.076-3.873-34.528a25.008 25.008 0 01.983-9c1.188-4.113 5.907-7.21 9.136-10.017 11.656-10.131 27.895-15.582 35.594-28.971 5.6-9.746 5.781-11.96 9.864-17.744 2.1-2.979 4.619-5.7 6.231-8.968 1.627-3.3 2.5-7.252 3.868-10.669 2.353-5.855 6.561-10.388 9.294-16.075.82-1.706 1.607-4.527.163-5.75-1.358-1.148-5.152-.419-6.7.447-9.1 5.085-12.108 12.814-18.09 23.466 3.357.516 3.469.447 5.907 2.165 1.854 1.306 1.348 3.207.467 5.3s-3.646 4.567-5.152 6.265a111.157 111.157 0 01-11.773 11.79c-1.612 1.378-3.184 2.367-4.9 3.614a52.15 52.15 0 00-6.589 5.268c-2.626 2.832-3.667 5.273-5.43 7.038z"
            className="d"
          />
          <path
            d="M214.201 83.518l3.024-7.216c.642-1.533 1.286-3.071 1.812-4.648.647-1.938 1.113-3.932 1.58-5.922l1.664-7.109c1.19-5.083 2.4-10.21 4.661-14.913a16.78 16.78 0 012.925-4.461 8.166 8.166 0 014.616-2.52c1.842-.259 3.908 2.7 2.612 6.379-.165.474-1.511 4.631-1.772 5.87-.8 3.814-1.365 8.119-2.17 11.933-.427 2.024-1.18 7.472-1.34 8.376-.454 2.585-2.4 4.629-3.439 7.04a12.209 12.209 0 00-5.554.943 11.976 11.976 0 00-3.8 2.192 262.913 262.913 0 00-4.819 4.056z"
            className="d"
          />
          <path
            d="M204.114 92.235a27.334 27.334 0 011.486-9.1c2.449-8.146 6.031-15.957 7.821-24.271.74-3.431 1.432-7.84 2.9-11.029a9.171 9.171 0 017.46-5.631 1.856 1.856 0 011.743.469 2.121 2.121 0 01-.022 1.955 113.33 113.33 0 00-4.55 16.13c-.269 1.19-1.412 6.867-1.735 8.042a27.575 27.575 0 01-1.711 4.152 62.561 62.561 0 00-3.515 9.576 5.42 5.42 0 01-.57 1.491 7.37 7.37 0 01-1.77 1.6 30.15 30.15 0 00-3.419 3.733 9.186 9.186 0 01-4.118 2.883z"
            className="d"
          />
          <path
            d="M193.793 98.179c-.2-2.841 1.422-5.441 2.661-8.006 3.1-6.426 3.989-13.661 4.87-20.743a16.646 16.646 0 011.424-5.734 5.661 5.661 0 014.629-3.256 4.93 4.93 0 011.516.225c1.15.343.792 2.261.523 3.054-.341.995-.131 6.759-.773 8.267a78.846 78.846 0 00-5.609 21.459 61.874 61.874 0 00-9.241 4.734z"
            className="d"
          />
        </g>
        <g>
          <path
            d="M53.064 117.157c-2.713-6.717-6.734-10.245-12.015-13.543-8.173-5.107-12.977-10.906-18.485-17.248-1.992-2.293-3.927-4.962-2.091-7.379 1.274-1.676 4.969-2.083 7.048-1.76 10.366 1.617 16.048 10.827 24.57 16.947 8.018 5.757 16.327 6.915 25.377 10.857s16.436 7.751 23.844 17.465c5.606 7.351 6.638 19.4 7.3 28.625 1.555 21.758 2.318 43.657 1.787 65.464-15.177 0-27.742.775-42.81 0a210.53 210.53 0 004.476-23.059c1.326-11.4 3.11-23.076 3.873-34.528a25.006 25.006 0 00-.983-9c-1.187-4.113-5.907-7.21-9.136-10.017-11.657-10.131-27.895-15.582-35.594-28.971-5.6-9.746-5.781-11.96-9.864-17.744-2.1-2.979-4.619-5.7-6.231-8.968-1.627-3.3-2.5-7.252-3.868-10.669C7.909 67.774 3.701 63.241.968 57.554c-.82-1.706-1.607-4.527-.163-5.75 1.358-1.148 5.152-.419 6.7.447 9.1 5.085 12.108 12.814 18.09 23.466-3.357.516-3.468.447-5.907 2.165-1.854 1.306-1.348 3.207-.467 5.3s3.646 4.567 5.152 6.265a111.153 111.153 0 0011.773 11.79c1.612 1.378 3.184 2.367 4.9 3.614a52.149 52.149 0 016.589 5.268c2.625 2.832 3.667 5.273 5.429 7.038z"
            className="d"
          />
          <path
            d="M41.021 83.518l-3.024-7.216c-.642-1.533-1.286-3.071-1.812-4.648-.647-1.938-1.113-3.932-1.58-5.922l-1.664-7.109c-1.19-5.083-2.4-10.21-4.661-14.913a16.782 16.782 0 00-2.925-4.461 8.166 8.166 0 00-4.616-2.52c-1.842-.259-3.908 2.7-2.612 6.379.165.474 1.511 4.631 1.772 5.87.8 3.814 1.365 8.119 2.17 11.933.427 2.024 1.18 7.472 1.34 8.376.454 2.585 2.4 4.629 3.439 7.04a12.21 12.21 0 015.554.943 11.975 11.975 0 013.8 2.192 262.913 262.913 0 014.819 4.056z"
            className="d"
          />
          <path
            d="M51.109 92.235a27.336 27.336 0 00-1.486-9.1c-2.449-8.146-6.031-15.957-7.82-24.271-.741-3.431-1.432-7.84-2.9-11.029a9.171 9.171 0 00-7.46-5.631 1.855 1.855 0 00-1.743.469 2.121 2.121 0 00.022 1.955 113.31 113.31 0 014.55 16.13c.269 1.19 1.412 6.867 1.735 8.042a27.561 27.561 0 001.711 4.152 62.561 62.561 0 013.515 9.576 5.422 5.422 0 00.57 1.491 7.368 7.368 0 001.77 1.6 30.145 30.145 0 013.419 3.733 9.186 9.186 0 004.117 2.883zM61.43 98.179c.2-2.841-1.422-5.441-2.661-8.006-3.1-6.426-3.989-13.661-4.871-20.743a16.646 16.646 0 00-1.424-5.734 5.662 5.662 0 00-4.629-3.256 4.929 4.929 0 00-1.516.225c-1.15.343-.792 2.261-.523 3.054.341.995.131 6.759.773 8.267a78.844 78.844 0 015.609 21.459 61.874 61.874 0 019.242 4.734z"
            className="d"
          />
        </g>
      </g>
    </g>
  </svg>
);
