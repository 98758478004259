import * as React from 'react';

export const SvgSunny = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="sunny_svg__sunny_svg__Sunny"
    viewBox="0 0 190.398 190.399"
    {...props}
  >
    <circle
      id="sunny_svg__sunny_svg__Sun"
      cx={62.086}
      cy={62.086}
      r={62.086}
      fill="#ffc107"
      transform="translate(33.113 33.113)"
    />
    <g id="sunny_svg__sunny_svg__Sun_Rays" fill="#ffd54f" data-name="Sun Rays">
      <path
        id="sunny_svg__sunny_svg__Path_1106"
        d="M238.805 24.835a4.139 4.139 0 004.139-4.139V4.139a4.139 4.139 0 00-8.278 0V20.7a4.14 4.14 0 004.139 4.135z"
        data-name="Path 1106"
        transform="translate(-143.606)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1107"
        d="M238.805 426.667a4.139 4.139 0 00-4.139 4.139v16.556a4.139 4.139 0 008.278 0v-16.556a4.139 4.139 0 00-4.139-4.139z"
        data-name="Path 1107"
        transform="translate(-143.606 -261.103)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1108"
        d="M447.362 234.667h-16.557a4.139 4.139 0 000 8.278h16.557a4.139 4.139 0 000-8.278z"
        data-name="Path 1108"
        transform="translate(-261.102 -143.607)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1109"
        d="M24.835 238.805a4.139 4.139 0 00-4.139-4.139H4.139a4.139 4.139 0 000 8.278H20.7a4.139 4.139 0 004.135-4.139z"
        data-name="Path 1109"
        transform="translate(0 -143.606)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1110"
        d="M126.2 52.028a4.138 4.138 0 003.593 2.069 4.08 4.08 0 002.069-.555 4.139 4.139 0 001.515-5.654L125.1 33.551a4.139 4.139 0 00-7.169 4.139z"
        data-name="Path 1110"
        transform="translate(-71.837 -19.28)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1111"
        d="M338.411 403.045a4.139 4.139 0 00-7.169 4.139l8.278 14.338a4.138 4.138 0 003.593 2.069 4.08 4.08 0 002.069-.554 4.139 4.139 0 001.515-5.654z"
        data-name="Path 1111"
        transform="translate(-202.376 -245.395)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1112"
        d="M405.076 133.758a4.08 4.08 0 002.069-.555l14.338-8.278a4.139 4.139 0 00-3.837-7.335q-.155.081-.3.175l-14.338 8.278a4.139 4.139 0 002.069 7.724v-.009z"
        data-name="Path 1112"
        transform="translate(-245.357 -71.671)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1113"
        d="M47.852 331.089l-14.338 8.278a4.139 4.139 0 002.069 7.724 4.081 4.081 0 002.069-.555l14.338-8.278a4.139 4.139 0 10-3.837-7.335q-.155.081-.3.175z"
        data-name="Path 1113"
        transform="translate(-19.243 -202.222)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1114"
        d="M33.263 124.911L47.6 133.19a4.08 4.08 0 002.061.555 4.139 4.139 0 002.069-7.724l-14.338-8.278a4.139 4.139 0 00-4.441 6.986q.147.094.3.175z"
        data-name="Path 1114"
        transform="translate(-18.992 -71.658)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1115"
        d="M421.247 339.354l-14.338-8.278a4.139 4.139 0 10-4.441 6.986q.147.094.3.175l14.338 8.278a4.082 4.082 0 002.069.555 4.139 4.139 0 002.069-7.724v.009z"
        data-name="Path 1115"
        transform="translate(-245.12 -202.21)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1116"
        d="M332.745 53.545a4.081 4.081 0 002.069.555 4.14 4.14 0 003.593-2.069l8.278-14.338a4.139 4.139 0 00-7.169-4.139l-8.278 14.338a4.139 4.139 0 001.507 5.653z"
        data-name="Path 1116"
        transform="translate(-202.363 -19.281)"
      />
      <path
        id="sunny_svg__sunny_svg__Path_1117"
        d="M131.82 401.492a4.139 4.139 0 00-5.654 1.515l-8.278 14.338A4.139 4.139 0 00119.4 423a4.081 4.081 0 002.069.555 4.139 4.139 0 003.593-2.069l8.278-14.338a4.139 4.139 0 00-1.521-5.653z"
        data-name="Path 1117"
        transform="translate(-71.803 -245.357)"
      />
    </g>
  </svg>
);
