import * as React from 'react';

export const Ph1: React.FC = (props) => (
  <svg
    {...props}
    className="smaller"
    xmlns="http://www.w3.org/2000/svg"
    width="235.457"
    height="207.559"
    viewBox="0 0 235.457 207.559"
  >
    <defs>
      <style>{`.a{fill:#eb9e6b;}.b{fill:#2f2e41;}.c{fill:#3f3d56;}`}</style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(87.81 95.028)">
        <path
          className="a"
          d="M.663,0,0,13.923l11.271,1.989V1.989Z"
          transform="matrix(-0.105, -0.995, 0.995, -0.105, 77.84, 91.435)"
        />
        <path
          className="a"
          d="M10.608,0l.663,13.923L0,15.912V1.989Z"
          transform="matrix(-0.105, -0.995, 0.995, -0.105, 106.402, 82.524)"
        />
        <path
          className="b"
          d="M534.9,426.88s-1.482-1.711-11.96,15.752S502.117,488.4,502.117,488.4l-10.565,22.526s-4.01,10.487,0,17.051,46.157-2.657,46.157-2.657l30.5-5.706s4.423-10.957,1.507-12.989-11.036,0-11.036,0-15.766-1.183-21.913-2.847-17.147,4.173-13.832,2.847c1.847-.716,4.932-8.286,4.932-8.286l12.975-25.219s-16.467,35.154-15.141,37.806-.777-.663-2.766,2.652.873,14.951,9.253,17.654,44.653-18.942,47.305-12.975c1.548,3.449,19.57-10.947,20.672-7.331,0,0-.982-10.978-3.726-12.587s-13.759,2.471-16.945.725a4.931,4.931,0,0,0-5.391,0s.094,2.082-3.6.505-2.934-1.136-6.625-.505-6.035,4.714-6.035,4.714,37.479-44.4,36.707-61.142-8.395-15.752-8.395-15.752Z"
          transform="translate(-489.77 -426.834)"
        />
        <path
          className="b"
          d="M26.831,1.956s1.989-1.989,2.652,0,9.983,5.194,6.912,10.627-17.182,9.1-19.2,11.107-5.559,6.278-14.841,4.952c-.093-.013-.859.511-2.142-1.932S3.8,20.917,5.62,18.346L18.014,3.814S20.864-3.348,26.831,1.956Z"
          transform="matrix(0.087, -0.996, 0.996, 0.087, 85.192, 110.013)"
        />
        <path
          className="b"
          d="M26.831,1.956s1.989-1.989,2.652,0,9.983,5.194,6.912,10.627-17.182,9.1-19.2,11.107-5.559,6.278-14.841,4.952c-.093-.013-.859.511-2.142-1.932S3.8,20.917,5.62,18.346L18.014,3.814S20.864-3.348,26.831,1.956Z"
          transform="matrix(0.087, -0.996, 0.996, 0.087, 115.637, 98.132)"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M8.3,0s5.068,12.037-6.336,17.739,30.41,16.472,34.845,2.534c0,0-11.4-4.435-6.336-17.739Z"
          transform="translate(57.373 57.733) rotate(-66)"
        />
        <circle
          className="a"
          cx="17.739"
          cy="17.739"
          r="17.739"
          transform="translate(32.803 43.604) rotate(-66)"
        />
        <path
          className="b"
          d="M6.143,11.26,2.973,9.991S9.6,2.7,18.821,3.33L16.227.475s6.339-2.538,12.1,4.124c3.029,3.5,6.534,7.618,8.719,12.255h3.394l-1.417,3.119,4.958,3.119-5.089-.56a17.261,17.261,0,0,1-.481,8.079l.136,2.465s-5.9-9.127-5.9-10.4v3.172S29.481,23,29.481,21.094l-1.729,2.221-.864-3.489L16.227,23.314l1.729-2.855-6.627.952,2.593-3.489s-7.491,4.124-7.78,7.613-4.034,7.93-4.034,7.93L.38,30.293S-2.213,16.018,6.143,11.26Z"
          transform="translate(20.386 40.181) rotate(-66)"
        />
        <path
          className="c"
          d="M45.238,4.947S34.467,4.556,30.983,0c0,0-22.491,7.482-25.025,13.817S16,60.307,16,60.307-7.219,95.342,2.285,95.342s22.135,19.332,34.806,14.9c7.852-2.723,14.117,8.232,22.292,6.737,0,0,7.159-22.709,5.891-25.877s2.771-8.859,4.672-13.294,1.267-5.068.634-6.969,1.267-4.435,1.267-6.969,0-5.721,0-5.721,16.468-42.428,12.667-46.23S61.367.791,61.367.791,56.008,7.481,45.238,4.947Z"
          transform="matrix(0.53, -0.848, 0.848, 0.53, 55.592, 86.584)"
        />
        <path
          className="a"
          d="M1.7,0s-5.7,29.777,3.8,27.243S10.566,0,10.566,0Z"
          transform="matrix(0.839, 0.545, -0.545, 0.839, 40.654, 173.766)"
        />
        <path
          className="c"
          d="M92.131,0,72.78,6.957A29.545,29.545,0,0,0,61.116,19.189c-3.352,7.029-2.325,27.23-2.325,27.23s1.241,7.033,1.241,7.667,1.3,2.6.034,4.5-1.928,4.713-.661,5.98c.723.657.642,2.684.734,3.657L0,91.249s3.211,6.2,6.379,9.367c0,0,61.036-13.988,62.936-17.156a35.436,35.436,0,0,1,2.857-4.281s-.762-1.6,2.405-4.773,2.623-8.119,1.989-8.753.495-5.4.495-6.035S87.852,17.21,87.852,17.21Z"
          transform="matrix(0.899, -0.438, 0.438, 0.899, 0, 92.597)"
        />
        <path
          className="a"
          d="M1.7,27.4S-4.006-2.381,5.5.153,10.566,27.4,10.566,27.4Z"
          transform="translate(197.574 137.418) rotate(172)"
        />
        <path
          className="c"
          d="M0,0,19.31,5.2c1.776.888,6.481,11.069,6.847,13.02l9.977,29.214S39.1,52.455,39.1,53.089s-1.267,1.766,0,3.666,3.644,2.2,2.377,3.465c-.723.657.092,3.517,0,4.49V69.2L34.609,119.12s-6.6-4.753-9.773-1.585c0,0,3.221-22.188,1.32-25.356s0-17.7,0-17.7,3.168-2.115,0-5.282c-2.1-2.1-1.194-4.1-1.32-6.075-.047-.647-2.78-5.737-2.78-6.371S3.519,16.842,3.519,16.842Z"
          transform="matrix(0.899, -0.438, 0.438, 0.899, 109.464, 20.553)"
        />
      </g>
    </g>
  </svg>
);
