import { css } from '@emotion/react';
import { theme } from '@web/features/theme';

export const styles = {
  title: css`
    font-family: ${theme.fonts.heading};
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes['4xl']};
    max-width: 18rem;
  `,

  subtitle: css`
    font-family: ${theme.fonts.heading};
    margin: ${theme.space[8]} 0;
    max-width: 80ch;
    text-align: left;
  `,

  actions: css`
    font-family: ${theme.fonts.body};
    flex: 1 1 auto;
    margin: ${theme.space[4]} auto;
    display: flex;
    gap: ${theme.space[4]};
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    a,
    button {
      cursor: pointer;
      color: ${theme.colors.info};
      background-color: transparent;
      border: none;
    }
  `,

  forgotPassword: css`
    cursor: pointer;
    color: ${theme.colors.info};
    background-color: transparent;
    border: none;
  `,
};
