/* eslint-disable no-nested-ternary */
import { WellnessCheckValues, WellnessTypes } from '@core/wellness';
import { Button } from '@web/components/button';
import { ErrorWrapper } from '@web/components/error-wrapper';
import { styles as formStyles } from '@web/components/form/form.styles';
import { SvgCheckCircle } from '@web/components/svgs/time-machine';
import { listAnimation, listChildAnimation } from '@web/features/theme';
import { useUserData } from '@web/features/users/use-user-data';
import { TILTSwal } from '@web/services/swal';
import { motion } from 'framer-motion';
import * as React from 'react';
import { WellnessPagination } from './wellness-pagination';
import { wellnessSteps } from './wellness-steps';
import { styles } from './wellness.styles';

export type WellnessCheckState = {
  status: 'READY' | 'ERROR' | 'SUBMITTING';
  error?: Error;
  step: number;
  values: WellnessCheckValues;
};

/**
 * # WellnessCheckPage
 * @packageDescription
 *
 * This page serves to allow a user to complete their wellness check.
 *
 * This page relies on the
 * - *Health components to display images
 * - a simple range input for data
 * - images in the `public/images` folder
 *
 * All animation come from the one input number and can be manipulated
 * any way you like.
 *
 * @todo refactor, this is kind of a mess
 * {@link initialSteps}
 */
export function WellnessCheck() {
  const [{ userData }, { onWellnessFinish }] = useUserData();

  const isUserDataAvailable = !!userData;
  const hasUserListened = userData?.f1_has_listened ?? false;
  const hasUserRecorded = Boolean(userData?.f1_last_recording) ?? false;

  const [state, setState] = React.useState<WellnessCheckState>({
    status: 'READY',
    step: 0,
    values: {
      spiritual: 5,
      emotional: 5,
      physical: 5,
      hope: 5,
    },
  });

  // a user's current step from the initialSteps above
  const currentStep = wellnessSteps[state.step];

  const currentImage = React.useCallback(
    (inputValue: number) => currentStep.cardImage?.(inputValue),
    [currentStep],
  );

  // the current value from the range slider
  const currentInputValue =
    typeof currentStep === 'object'
      ? state.values[currentStep.type as WellnessTypes] || 0
      : 0;

  React.useEffect(() => {
    if (isUserDataAvailable && hasUserRecorded === false) {
      wellnessHelpSwal();
    } else {
      TILTSwal.close();
    }

    //  we really only care about the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserDataAvailable]);

  // function to run when a user has completed the step
  const onFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (state.step === wellnessSteps.length - 1) {
      // on the last step, submit the values
      return onSubmit();
    }

    // go to the next step
    return setState((prev) => ({ ...prev, step: state.step + 1 }));
  };

  // update state to reflect the value of the range input
  const onSliderChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [evt.target.name]: Math.round(Number(evt.target.value)),
      },
    }));

  // verify that all values are entered as number
  const validValues = React.useMemo(
    () =>
      Object.values(state.values).every((value) => typeof value === 'number')
        ? state.values
        : false,
    [state],
  );

  // submit the wellness check
  const onSubmit = React.useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, status: 'SUBMITTING' }));

      if (validValues === false) {
        throw new Error('Please complete all 3 wellness checks');
      }

      if (!hasUserListened) {
        return await onWellnessFinish(validValues);
      }

      return await wellnessCompleteSwal().then((result) => {
        if (result) return onWellnessFinish(validValues);

        return null;
      });
    } catch (error) {
      return setState((prev) => ({
        ...prev,
        status: 'ERROR',
        error: error as Error,
      }));
    }
  }, [hasUserListened, onWellnessFinish, validValues]);

  switch (state.status) {
    case 'ERROR': {
      return (
        <ErrorWrapper
          title="An error occured submitting your wellness check"
          error={state.error}
        />
      );
    }

    case 'SUBMITTING': {
      return (
        <motion.h1
          variants={listChildAnimation}
          css={{ textAlign: 'center', margin: '10%', fontSize: 16 }}
        >
          Sending wellness into spacetime
        </motion.h1>
      );
    }

    case 'READY':
    default: {
      return (
        <motion.section
          key={currentStep.type}
          variants={listAnimation}
          css={() => styles.cardContainer(currentStep, currentInputValue)}
        >
          <motion.h1 variants={listChildAnimation} css={styles.cardTitle}>
            {currentStep.title}
          </motion.h1>

          {currentImage(currentInputValue)}

          <motion.form
            className="rr-ignore"
            variants={listChildAnimation}
            onSubmit={onFormSubmit}
            css={styles.cardSlider}
          >
            <input
              type="range"
              min="0"
              max="10"
              step="1"
              id={currentStep.type}
              name={currentStep.type}
              css={formStyles.formInput}
              onChange={onSliderChange}
              value={currentInputValue}
              className="rr-ignore slide"
            />

            <Button
              type="submit"
              className="highlight"
              css={{
                gridArea: 'submit',
                display: 'block',
                margin: '0 auto',
                width: '50%',
              }}
            >
              {currentStep.buttonText}
            </Button>
          </motion.form>

          <WellnessPagination currentStep={state.step || 0} />
        </motion.section>
      );
    }
  }
}

function wellnessHelpSwal() {
  TILTSwal.fire({
    iconHtml: <SvgCheckCircle />,
    title: <p css={{ textAlign: 'center' }}>On your way</p>,
    html: (
      <div css={{ textAlign: 'center' }}>
        <p>The first step to traveling in time is checking in with yourself</p>
      </div>
    ),
  });
}

function wellnessCompleteSwal() {
  return TILTSwal.fire({
    iconHtml: <SvgCheckCircle />,
    title: <p css={{ textAlign: 'center' }}>Great Work!</p>,
    html: (
      <div css={{ textAlign: 'center' }}>
        <p>You've completed your wellness check for the day!</p>
        <p>
          Go to the insights tab to see how you respond to life's ups and downs
        </p>
      </div>
    ),
  });
}
