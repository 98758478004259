import { css } from '@emotion/react';
import { fadeInUp, theme, typography, variantProps } from '@web/features/theme';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useIsRunningAsIOSApp } from '@web/utils/ios-app';

/**
 * # Info
 *
 * Simple Components to dispaly links to our terms and conditions as well as a
 *  video explaining how to install the app on your phone
 */
export function Info() {
  const [isRunningAsIOSApp] = useIsRunningAsIOSApp();
  const showAddAppIcon = !isRunningAsIOSApp;

  return (
    <motion.div
      css={styles.termsAndConditions}
      variants={fadeInUp}
      {...variantProps}
    >
      {showAddAppIcon && (
        <Link href="/help" css={styles.installLink} passHref>
          Get a Time Machine app icon
        </Link>
      )}

      <p css={{ fontSize: typography.fontSizes.xs }}>
        By signing in, you agree to our{' '}
        <Link href="/help/terms" passHref>
          Terms &amp; Conditions
        </Link>
      </p>

      <p css={{ fontSize: typography.fontSizes.xs }}>
        If you run into technical problems, visit the{' '}
        <Link href="/help" passHref>
          help page
        </Link>
        <br /> or send an email to{' '}
        <a href="mailto:help@loveandtime.org" target="_new">
          help@loveandtime.org
        </a>
      </p>
    </motion.div>
  );
}

export const styles = {
  termsAndConditions: css`
    text-align: center;
    font-family: ${theme.fonts.body};
    font-size: ${theme.fontSizes.xs};
    margin: ${theme.space[8]} auto;
    display: grid;
    gap: ${theme.space[4]};

    a {
      text-decoration: underline;
      color: ${theme.colors.info};
      border-bottom: ${theme.space[2]};
      font-size: ${theme.fontSizes.xs};
    }
  `,

  installLink: css`
    background: none;
    border: none;
    text-decoration: underline;
    color: ${theme.colors.yellow['400']} !important;
    font-size: ${theme.fontSizes.md} !important;
  `,
};
