import { css } from '@emotion/react';
import { fadeInDown, theme, variantProps } from '@web/features/theme';
import { motion } from 'framer-motion';

export type LoadingProps = {
  number?: number;
  children?: React.ReactNode;
  type?: 'horizontal' | 'spinner';
};

/**
 * # Loading
 *
 * Component to inform user of background activity.
 * Displays a list of bouncing circles and an optional title.
 */
export function Loading({
  number = 8,
  type = 'horizontal',
  children,
  ...props
}: LoadingProps) {
  switch (type) {
    case 'spinner': {
      return (
        <motion.div
          css={styles.spinner}
          variants={loadingContainerVariants}
          {...variantProps}
        >
          <p>{children}</p>

          <div>
            {Array.from({ length: 12 }, (_, idx) => idx).map((key) => (
              <motion.div key={key} />
            ))}
          </div>
        </motion.div>
      );
    }

    default:
    case 'horizontal': {
      return (
        <motion.section variants={fadeInDown} {...props}>
          <h6 css={{ textAlign: 'center', marginBottom: '1rem' }}>
            {children}
          </h6>

          <motion.div
            css={styles.loadingContainer}
            variants={loadingContainerVariants}
            {...variantProps}
          >
            {Array.from({ length: number }, (_, idx) => idx).map((key) => (
              <motion.span key={key} variants={loadingCircleVariants} />
            ))}
          </motion.div>
        </motion.section>
      );
    }
  }
}

const loadingContainerVariants = {
  initial: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  initial: {
    y: '0%',
  },
  animate: {
    y: '100%',
    transition: {
      duration: 0.5,
      repeat: Infinity,
      ease: 'easeInOut',
    },
  },
};

const styles = {
  loadingContainer: css`
    margin: 0 auto;
    display: flex;
    justify-content: center;
  `,

  loadingCircle: css`
    display: block;
    width: ${theme.space[2]};
    height: ${theme.space[2]};
    background-color: ${theme.colors.info};
    border-radius: ${theme.radii.full};
  `,

  spinner: css`
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;
    z-index: 1;

    p {
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-size: ${theme.fontSizes.xs};
    }

    div {
      transform-origin: 80px 80px;
      animation: spinner 1.2s linear infinite;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 6px;
        left: 74px;
        width: 12px;
        height: 36px;
        border-radius: 20%;
        background: ${theme.colors.teal[500]};
      }

      &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }

      &:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }

      &:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }

      &:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }

      &:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }

      &:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }

      &:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }

      &:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }

      &:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }

      &:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }

      &:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }

      &:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }

      @keyframes spinner {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  `,
};
