import * as React from 'react';

export const Ph3: React.FC = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="159.221"
    height="349.843"
    viewBox="0 0 159.221 349.843"
  >
    <defs>
      <style>{`.a{fill:#eb9e6b;}.b{fill:#2f2e41;}.c{fill:#3f3d56;}`}</style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(64.449 139.329)">
        <path
          className="a"
          d="M528.065,618.937,527.4,632.86l11.271,1.989V620.926Z"
          transform="translate(-469.498 -458.11)"
        />
        <path
          className="a"
          d="M431.04,618.937l.663,13.923-11.271,1.989V620.926Z"
          transform="translate(-407.479 -458.11)"
        />
        <path
          className="b"
          d="M544.7,424s-8.538,2.036-10.565,17.9-.194,38.264,2.458,45.557c0,0-2.463,11.46-1.137,14.776s2.463,6.63,1.137,7.956-1.989,41.105,0,44.42,3.777,32.926,3.777,32.926,12.8,4.2,15.451-3.09-1.359-31.081,1.293-33.07S559.6,519.9,559.6,519.9s-1.989-1.527,0-2.853,4.393-3.535,2.4-6.85-3.315-1.326,0-2.652c1.847-.716-.23-4.256,1.451-5.3l8.151-37.63s3.483,47.575,4.809,50.227,1.989,1.748,0,5.063-.663,4.527,0,6.516.345,22.234,3,28.2c1.548,3.449,6.2,29.309,7.3,32.926,0,0,12.408,3.315,13.734,0s-1.989-21.663,0-22.326,0-7.057,0-12.361,2.829-32.955,2.829-32.955-1.989-3.737-.663-7.052.663-2.652,0-5.3-1.326-1.8,0-3.79,0-11.331,0-11.331,3.911-33.777,3.138-50.516-8.395-15.752-8.395-15.752Z"
          transform="translate(-530.019 -424.002)"
        />
        <path
          className="b"
          d="M639.2,722.458s-1.989-1.989-2.652,0-.663,20.553-.663,20.553,5.3,3.978,5.3,5.967,3.978,8.619,13.26,7.293l.275-.042a8.778,8.778,0,0,0,6.527-12.425l-9.454-20.021S645.164,717.154,639.2,722.458Z"
          transform="translate(-579.968 -545.931)"
        />
        <path
          className="b"
          d="M529.419,722.458s1.989-1.989,2.652,0,.663,20.553.663,20.553-5.3,3.978-5.3,5.967-3.978,8.619-13.26,7.293l-.276-.042a8.778,8.778,0,0,1-6.527-12.425l9.454-20.021S523.452,717.154,529.419,722.458Z"
          transform="translate(-506.522 -545.931)"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M8.3,0s5.068,12.037-6.336,17.739,30.41,16.472,34.845,2.534c0,0-11.4-4.435-6.336-17.739Z"
          transform="translate(38.823 60.76) rotate(-50)"
        />
        <circle
          className="a"
          cx="17.739"
          cy="17.739"
          r="17.739"
          transform="translate(19.099 40.406) rotate(-50)"
        />
        <path
          className="b"
          d="M6.143,11.26,2.973,9.991S9.6,2.7,18.821,3.33L16.227.475s6.339-2.538,12.1,4.124c3.029,3.5,6.534,7.618,8.719,12.255h3.394l-1.417,3.119,4.958,3.119-5.089-.56a17.261,17.261,0,0,1-.481,8.079l.136,2.465s-5.9-9.127-5.9-10.4v3.172S29.481,23,29.481,21.094l-1.729,2.221-.864-3.489L16.227,23.314l1.729-2.855-6.627.952,2.593-3.489s-7.491,4.124-7.78,7.613-4.034,7.93-4.034,7.93L.38,30.293S-2.213,16.018,6.143,11.26Z"
          transform="translate(8.106 33.694) rotate(-50)"
        />
        <path
          className="c"
          d="M45.238,4.947S34.467,4.556,30.983,0c0,0-22.491,7.482-25.025,13.817S16,60.307,16,60.307-7.219,95.342,2.285,95.342s22.135,19.332,34.806,14.9c7.852-2.723,14.117,8.232,22.292,6.737,0,0,7.159-22.709,5.891-25.877s2.771-8.859,4.672-13.294,1.267-5.068.634-6.969,1.267-4.435,1.267-6.969,0-5.721,0-5.721,16.468-42.428,12.667-46.23S61.367.791,61.367.791,56.008,7.481,45.238,4.947Z"
          transform="matrix(0.848, -0.53, 0.53, 0.848, 25, 77.717)"
        />
        <path
          className="a"
          d="M1.7,0s-5.7,29.777,3.8,27.243S10.566,0,10.566,0Z"
          transform="translate(34.37 186.556) rotate(-8)"
        />
        <path
          className="c"
          d="M58.247,0,38.9,6.957A29.545,29.545,0,0,0,27.231,19.189c-3.352,7.029-2.325,27.23-2.325,27.23s1.241,7.033,1.241,7.667,1.3,2.6.034,4.5-1.928,4.713-.661,5.98c.723.657.642,2.684.734,3.657L0,113.524s7.844.41,11.012,3.578c0,0,22.518-30.473,24.419-33.641a35.436,35.436,0,0,1,2.857-4.281s-.762-1.6,2.405-4.773,2.623-8.119,1.989-8.753.495-5.4.495-6.035S53.968,17.21,53.968,17.21Z"
          transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 79.813)"
        />
        <path
          className="a"
          d="M1.7,27.4S-4.006-2.381,5.5.153,10.566,27.4,10.566,27.4Z"
          transform="matrix(-0.951, -0.309, 0.309, -0.951, 130.328, 185.647)"
        />
        <path
          className="c"
          d="M504.5,236.851l19.31,5.2c1.776.888,6.482,11.069,6.847,13.02l9.977,29.214s2.965,5.017,2.965,5.651-1.267,1.766,0,3.666,3.644,2.2,2.377,3.465c-.723.657.092,3.517,0,4.49v4.49l-6.867,49.919s-6.6-4.753-9.773-1.585c0,0,3.221-22.188,1.32-25.356s0-17.7,0-17.7,3.168-2.115,0-5.282c-2.1-2.1-1.194-4.1-1.32-6.075-.047-.647-2.78-5.737-2.78-6.371s-18.537-39.914-18.537-39.914Z"
          transform="translate(-402.131 -194.866)"
        />
      </g>
    </g>
  </svg>
);
