import * as React from 'react';

export const SHWoman: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="243.872"
    height="244.417"
    viewBox="0 0 243.872 244.417"
    {...props}
  >
    <defs>
      <style>{`.a{fill:#2f2e41;}.b{fill:#575988;}.c{opacity:0.05;}.d{fill:#a0616a;}.e{opacity:0.1;}.f{fill:#fff;opacity:0.07;}.g{fill:#fc6681;}`}</style>
    </defs>
    <g transform="translate(-95.607 -11836.039)">
      <g transform="translate(36.21 11665.402)">
        <circle
          className="a"
          cx="35.28"
          cy="35.28"
          r="35.28"
          transform="translate(148.163 170.637)"
        />
      </g>
      <path
        className="b"
        d="M264.1,645.472l2.1,24.835,146.639-.876-2.8-27.112-49.577-5.769L273.526,641.8Z"
        transform="translate(-120.301 11405.626)"
      />
      <path
        className="c"
        d="M264.1,645.472l2.1,24.835,146.639-.876-2.8-27.112-49.577-5.769L273.526,641.8Z"
        transform="translate(-120.301 11405.626)"
      />
      <path
        className="d"
        d="M594.186,753.82l-48.816,4.571s33.445,8.517,52.971,4.379Z"
        transform="translate(-353.899 11314.631)"
      />
      <path
        className="e"
        d="M594.186,753.82l-48.816,4.571s33.445,8.517,52.971,4.379Z"
        transform="translate(-353.899 11314.631)"
      />
      <path
        className="d"
        d="M557.055,434.29s9.557,25.136-4.571,36.352-2.491,42.587-2.491,42.587l54.427-2.5s5.224-31.786,2.318-39.264c0,0-12.707.213-15.614-7.68s-4.987-29.5-4.987-29.5Z"
        transform="translate(-353.952 11459.254)"
      />
      <path
        className="b"
        d="M479.684,697.935s-32.692-2.034-62.1,5.773-21.629,17.11-20.357,21.852,9.83,12.6,18.993,15.579,67.417,3.114,82.164-6.23c0,0,5.611-15.995,0-19.109H427.135l52.549-1.04Z"
        transform="translate(-286.352 11337.516)"
      />
      <path
        className="b"
        d="M646.089,699.331s18.228-6.929,46.16-5.1c0,0,39.68-1.243,26.384,24.085S631.8,737.431,631.8,737.431s-4.379-9.349,0-11.84,42.379-18.491,54.219-17.659l-41.681,7.154Z"
        transform="translate(-392.138 11341.605)"
      />
      <path
        className="e"
        d="M598.1,447.5c-.794-5.917-1.259-10.74-1.259-10.74H567.76a56.66,56.66,0,0,1,2.781,11.758A22.853,22.853,0,0,0,598.1,447.5Z"
        transform="translate(-364.033 11458.136)"
      />
      <circle
        className="d"
        cx="22.848"
        cy="22.848"
        r="22.848"
        transform="translate(196.146 11863.739)"
      />
      <path
        className="d"
        d="M480.226,492.191s-26.018,6.459-31.64,12.672-7.411,28.994-7.411,28.994,3.353,35.817-6.2,40.6L402.536,602.7s-25.339-22.224-31.573-9.141,29.915,17.867,29.915,17.867l28.1-9.9,21.679-11.186s12.88-9.37,13.5-16.437l1.945-33.241,21.605-8.588h34.9l4.675-13.345s2.089,13.543.84,19.98,4.535,40.843,4.535,40.843,6.777,10.036,12.387,12.735l32.405,11.632s34.272,3.323,36.56-11.632-33.653,2.129-33.653,2.129l-25.776-19.963s-2.075-8.309-2.907-14.128-1.607-26.467-1.607-26.467,3.031-31.544-19.823-36.109l-25.1-8.047Z"
        transform="translate(-274.632 11434.175)"
      />
      <path
        className="e"
        d="M536.644,498.354,540.9,526.5s-4.379,13.088,0,25.968-19.247,42.241-2.9,56.641,50.94,17.785,68.28.958-9.925-49.673-1.773-63.832.517-22.74.517-22.74l1.178-25.286-7.49-2.072-3.4,24.972s-28.667,19.941-49.856,2.5L543.092,496Z"
        transform="translate(-349.431 11433.311)"
      />
      <path
        className="f"
        d="M573.716,558.467a16.832,16.832,0,0,0-21.786-6.125A31.66,31.66,0,0,0,573.716,558.467Z"
        transform="translate(-356.868 11406.626)"
      />
      <path
        className="f"
        d="M613.24,552.817A16.8,16.8,0,0,1,635,546.692,31.837,31.837,0,0,1,613.24,552.817Z"
        transform="translate(-384.618 11409.183)"
      />
      <path
        className="e"
        d="M536.644,497.145l4.259,26.691s-4.379,13.088,0,25.968-19.35,44.27-3.026,58.894,50.983,16.429,68.323-.4-10.759-49.5-1.34-64.935.164-22.541.164-22.541l1.338-23.426-7.729-2.231-3.325,23.271s-28.667,19.941-49.856,2.491l-2.42-26.139Z"
        transform="translate(-349.431 11434.519)"
      />
      <path
        className="g"
        d="M536.436,497.281,540.9,524.6s-4.379,13.088,0,25.968-20.211,46.3-.867,59.273,53.74,14.409,67.623-2.887c13.341-16.621-10.02-43.513-3.468-61.457a9.025,9.025,0,0,1,.668-1.372c7.76-13.168.164-22.536.164-22.536l1.258-24.081-7.65-2.071L595.307,519.2s-28.667,19.941-49.856,2.491l-2.392-26.568Z"
        transform="translate(-349.431 11434.175)"
      />
      <path
        className="d"
        d="M575.73,722.548l10.8-1.462s12.464-15.995,21.4-3.739c0,0,3.739,7.893,8.309,9.344a61.37,61.37,0,0,0,9.557,2.08L607.3,733.967s-8.1,4.779-16.619,3.531a32.226,32.226,0,0,0-14.955,1.248Z"
        transform="translate(-367.64 11333.229)"
      />
    </g>
  </svg>
);
