import { ErrorWrapper } from '@web/components/error-wrapper';
import { Loading } from '@web/components/loading';
import { useWindowSize } from '@web/components/use-window-size';
import { AuthStatus } from '@web/features/auth/auth.state';
import { SignIn } from '@web/features/auth/sign-in';
import { useAuth } from '@web/features/auth/use-auth';
import { useUserData } from '@web/features/users/use-user-data';
import { UserStateStatus } from '@web/features/users/user.state';
import { WellnessCheck } from '@web/features/wellness/wellness';
import * as React from 'react';
import { BaseLayout, LayoutConfig, toggleLoadingScreen } from './base';
import { useUserRedirect } from './use-user-redirect';

/**
 * # UserLayout
 *
 * Provides a layout for regular authenticated users.
 */
export function UserLayout({ children, ...props }: LayoutConfig) {
  const [{ status: authStatus, error: authError, user }] = useAuth();
  const [{ status: userDataStatus, error: userError, userData, userStatus }] =
    useUserData();

  const { isMobile } = useWindowSize();
  useUserRedirect({ userStatus });

  const isFulfilled =
    authStatus === AuthStatus.FULFILLED &&
    userDataStatus === UserStateStatus.FULFILLED;

  const shouldRenderWellnessCheck =
    (userData && !userData.last_wellness_check) ||
    userStatus?.global.shouldWellness;

  React.useEffect(() => {
    if (isFulfilled) {
      toggleLoadingScreen(false);
    }

    return () => {
      toggleLoadingScreen(true);
    };
  }, [authStatus, isFulfilled, userDataStatus]);

  if (
    authStatus === AuthStatus.PENDING ||
    userDataStatus === UserStateStatus.PENDING
  ) {
    return (
      <BaseLayout>
        <Loading>
          One sec, your {process.env.NEXT_PUBLIC_APP_NAME} is loading
        </Loading>
      </BaseLayout>
    );
  }

  if (!!authError || !!userError) {
    return (
      <BaseLayout {...props}>
        {/missing or insufficient permissions/i.test(
          userError?.message || '',
        ) ? (
          <Loading>Missing or Insufficient Permissions</Loading>
        ) : (
          <ErrorWrapper
            title="There was an error with your account"
            error={authError || userError}
          />
        )}
      </BaseLayout>
    );
  }

  if (typeof user === 'undefined') {
    return (
      <BaseLayout
        withBlur
        withMenu={false}
        withHomeButton={false}
        withBackButton={false}
        backgroundImage={
          isMobile ? 'onboarding-bg--mobile.png' : 'onboarding-bg--desktop.png'
        }
      >
        <SignIn />
      </BaseLayout>
    );
  }

  if (typeof userData === 'undefined') {
    return (
      <BaseLayout withMenu={false} {...props}>
        <Loading>Retrieving your recent activity</Loading>
      </BaseLayout>
    );
  }

  if (shouldRenderWellnessCheck) {
    return (
      <BaseLayout withMenu={false} fullHeight fullWidth>
        <WellnessCheck />
      </BaseLayout>
    );
  }

  return <BaseLayout {...props}>{children}</BaseLayout>;
}
