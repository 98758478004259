import { UserStatus } from '@core/users/users';
import { isProduction } from '@core/utils/env';
import { useRouter } from 'next/router';
import * as React from 'react';

export function useUserRedirect({ userStatus }: { userStatus?: UserStatus }) {
  const router = useRouter();

  React.useEffect(() => {
    const redirect = () => {
      if (!userStatus) return false;

      switch (router.pathname) {
        case '/listen': {
          if (userStatus.f1.shouldListen === false) {
            return router.replace('/');
          }

          return false;
        }

        case '/record': {
          if (userStatus.f1.shouldRecord === false) {
            return router.replace('/');
          }

          return false;
        }

        default: {
          if (
            router.pathname.startsWith('/meditations') &&
            userStatus.f2.isUnlocked === false
          ) {
            return router.replace('/');
          }
          if (
            router.pathname.startsWith('/hopes') &&
            userStatus.f3.isUnlocked === false
          ) {
            return router.replace('/');
          }

          return false;
        }
      }
    };

    if (isProduction()) {
      redirect();
    }
  }, [router, userStatus]);
}
