import * as React from 'react';

export const Ph7: React.FC = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="186.738"
    height="359.62"
    viewBox="0 0 186.738 359.62"
  >
    <defs>
      <style>{`.a{fill:#eb9e6b;}.b{fill:#2f2e41;}.c{fill:#3f3d56;}`}</style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(47.151 149.106)">
        <path
          className="a"
          d="M528.065,618.937,527.4,632.86l11.271,1.989V620.926Z"
          transform="translate(-469.498 -458.11)"
        />
        <path
          className="a"
          d="M431.04,618.937l.663,13.923-11.271,1.989V620.926Z"
          transform="translate(-407.479 -458.11)"
        />
        <path
          className="b"
          d="M544.7,424s-8.538,2.036-10.565,17.9-.194,38.264,2.458,45.557c0,0-2.463,11.46-1.137,14.776s2.463,6.63,1.137,7.956-1.989,41.105,0,44.42,3.777,32.926,3.777,32.926,12.8,4.2,15.451-3.09-1.359-31.081,1.293-33.07S559.6,519.9,559.6,519.9s-1.989-1.527,0-2.853,4.393-3.535,2.4-6.85-3.315-1.326,0-2.652c1.847-.716-.23-4.256,1.451-5.3l8.151-37.63s3.483,47.575,4.809,50.227,1.989,1.748,0,5.063-.663,4.527,0,6.516.345,22.234,3,28.2c1.548,3.449,6.2,29.309,7.3,32.926,0,0,12.408,3.315,13.734,0s-1.989-21.663,0-22.326,0-7.057,0-12.361,2.829-32.955,2.829-32.955-1.989-3.737-.663-7.052.663-2.652,0-5.3-1.326-1.8,0-3.79,0-11.331,0-11.331,3.911-33.777,3.138-50.516-8.395-15.752-8.395-15.752Z"
          transform="translate(-530.019 -424.002)"
        />
        <path
          className="b"
          d="M639.2,722.458s-1.989-1.989-2.652,0-.663,20.553-.663,20.553,5.3,3.978,5.3,5.967,3.978,8.619,13.26,7.293l.275-.042a8.778,8.778,0,0,0,6.527-12.425l-9.454-20.021S645.164,717.154,639.2,722.458Z"
          transform="translate(-579.968 -545.931)"
        />
        <path
          className="b"
          d="M529.419,722.458s1.989-1.989,2.652,0,.663,20.553.663,20.553-5.3,3.978-5.3,5.967-3.978,8.619-13.26,7.293l-.276-.042a8.778,8.778,0,0,1-6.527-12.425l9.454-20.021S523.452,717.154,529.419,722.458Z"
          transform="translate(-506.522 -545.931)"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M602.749,171.473s5.068,12.037-6.336,17.739,30.41,16.472,34.845,2.534c0,0-11.4-4.435-6.336-17.739Z"
          transform="translate(-511.252 -130.545)"
        />
        <circle
          className="a"
          cx="17.739"
          cy="17.739"
          r="17.739"
          transform="translate(86.112 12.735)"
        />
        <path
          className="b"
          d="M602.708,95.26l-3.169-1.269s6.627-7.3,15.848-6.661l-2.593-2.855s6.339-2.538,12.1,4.124c3.029,3.5,6.534,7.618,8.719,12.255h3.394l-1.417,3.119,4.958,3.119-5.089-.56a17.261,17.261,0,0,1-.481,8.079l.136,2.465s-5.9-9.127-5.9-10.4v3.172s-3.169-2.855-3.169-4.758l-1.729,2.221-.864-3.489-10.661,3.489,1.729-2.855-6.627.952,2.593-3.489s-7.491,4.124-7.78,7.613-4.034,7.93-4.034,7.93l-1.729-3.172S594.353,100.018,602.708,95.26Z"
          transform="translate(-512.378 -84)"
        />
        <path
          className="c"
          d="M580.628,213.239s-10.77-.391-14.255-4.947c0,0-22.491,7.482-25.025,13.817s8.87,34.845,8.87,34.845-24.21,58.287-14.706,58.287,35.614,7.6,48.285,3.168c7.852-2.723,13.366-1.673,21.541-3.168,0,0,1.267-19.109,0-22.276s-1.9-2.27,0-6.7-.926-6.023-1.56-7.924,1.56-3.358,1.56-5.892-1.56-8.94-1.56-8.94,19.445-40.9,15.644-44.7-22.664-9.724-22.664-9.724S591.4,215.773,580.628,213.239Z"
          transform="translate(-481.004 -150.879)"
        />
        <path
          className="a"
          d="M1.7,0s-5.7,29.777,3.8,27.243S10.566,0,10.566,0Z"
          transform="matrix(0.407, -0.914, 0.914, 0.407, 50.905, 146.797)"
        />
        <path
          className="c"
          d="M16.237,92.634,30.671,81.721c1.775-.888,6.481-11.069,6.847-13.02l9.909-19.808s3.821-11.682,3.821-12.316A6.522,6.522,0,0,1,52.477,32.6c1.267-1.9,4.112-5.587,2.845-6.854-.723-.657,3.206-5.731,3.114-6.7L55.588,13.32l-.608-.138c-3.287-.744-9.605-4.12-15.3-5.409C23.208,4.044,1.469,0,1.469,0S3.168,12.714,0,9.546c0,0,13.3,5.715,17.214,7.7s11.534,3.544,11.534,3.544,5.674,1.363,5.548,3.334c-.047.647-12.616,23.7-12.616,24.329S5.387,67.576,5.387,67.576Z"
          transform="matrix(-0.875, -0.485, 0.485, -0.875, 53.401, 147.023)"
        />
        <path
          className="a"
          d="M1.7,27.4S-4.006-2.381,5.5.153,10.566,27.4,10.566,27.4Z"
          transform="translate(103.637 158.051) rotate(-101)"
        />
        <path
          className="c"
          d="M11.361,0l19.31,5.2c1.775.888,6.481,11.069,6.847,13.02l9.909,19.808s3.821,11.682,3.821,12.316a6.522,6.522,0,0,0,1.228,3.977c1.267,1.9,4.112,5.587,2.845,6.855-.723.657,3.206,5.731,3.114,6.7l-2.848,5.722-.608.138c-3.287.744-9.605,4.12-15.3,5.409C23.208,82.882,1.469,86.926,1.469,86.926S3.168,74.212,0,77.38c0,0,13.3-5.715,17.214-7.7s11.534-3.544,11.534-3.544S34.423,64.772,34.3,62.8c-.047-.647-12.616-23.7-12.616-24.329S5.387,19.35,5.387,19.35Z"
          transform="translate(104.087 68.922) rotate(-16)"
        />
      </g>
    </g>
  </svg>
);
