import * as React from 'react';

export const SvgThunderBolt = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 45.381 74.555" {...props}>
    <path
      id="thunder-bolt_svg__thunder-bolt_svg__Thunder_Bolt"
      fill="#fbb540"
      d="M228.966 192L216 217.932h19.449l-16.208 48.623 42.14-61.589h-19.449L248.415 192z"
      data-name="Thunder Bolt"
      transform="translate(-216 -192)"
    />
  </svg>
);
