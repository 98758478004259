import * as React from 'react';

export const SvgRain = (props: React.SVGProps<SVGSVGElement>) => (
  <svg id="rain_svg__rain_svg__Rain" viewBox="0 0 153.44 70.663" {...props}>
    <path
      id="rain_svg__rain_svg__Path_1076"
      fill="#a4c4e7"
      d="M184.831 374.649a8.838 8.838 0 01-8.374-11.629l17.662-52.986a8.828 8.828 0 1116.748 5.588L193.2 368.608a8.83 8.83 0 01-8.369 6.041z"
      data-name="Path 1076"
      transform="translate(-151.771 -303.986)"
    />
    <path
      id="rain_svg__rain_svg__Path_1077"
      fill="#a4c4e7"
      d="M280.831 348.156a8.838 8.838 0 01-8.374-11.629l8.831-26.493a8.828 8.828 0 1116.748 5.588l-8.836 26.493a8.83 8.83 0 01-8.369 6.041z"
      data-name="Path 1077"
      transform="translate(-210.074 -303.986)"
    />
    <path
      id="rain_svg__rain_svg__Path_1078"
      fill="#a4c4e7"
      d="M120.831 348.156a8.838 8.838 0 01-8.374-11.629l8.831-26.493a8.828 8.828 0 1116.748 5.588l-8.836 26.493a8.83 8.83 0 01-8.369 6.041z"
      data-name="Path 1078"
      transform="translate(-112.001 -303.986)"
    />
    <path
      id="rain_svg__rain_svg__Path_1079"
      fill="#a4c4e7"
      d="M184.831 374.649a8.838 8.838 0 01-8.374-11.629l17.662-52.986a8.828 8.828 0 1116.748 5.588L193.2 368.608a8.83 8.83 0 01-8.369 6.041z"
      data-name="Path 1079"
      transform="translate(-88.169 -303.986)"
    />
    <path
      id="rain_svg__rain_svg__Path_1080"
      fill="#a4c4e7"
      d="M280.831 348.156a8.838 8.838 0 01-8.374-11.629l8.831-26.493a8.828 8.828 0 1116.748 5.588l-8.836 26.493a8.83 8.83 0 01-8.369 6.041z"
      data-name="Path 1080"
      transform="translate(-145.051 -303.986)"
    />
  </svg>
);
