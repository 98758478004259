import { WellnessTypes } from '@core/wellness';
import * as React from 'react';
import {
  EmotionalHealth,
  PhysicalHealth,
  SpiritualHealth,
} from './wellness-images';

/**
 * # initialSteps
 *
 * This array controls the wellness check images and backgrounds as a
 *  user goes through the steps
 *
 * All based on the inputValue from the range slider, it's important to
 *  pass this number along to the components.
 */

export const wellnessSteps: Array<{
  type: WellnessTypes;
  title: string;
  buttonText: string;
  cardImage?: (inputValue: number) => React.ReactNode;
  cardBackground?: (inputValue: number) => string;
}> = [
  {
    type: 'physical',
    title: 'How is your physical health today, overall?',
    buttonText: 'Next',
    cardImage: (inputValue: number) => (
      <PhysicalHealth inputValue={inputValue} />
    ),
  },
  {
    type: 'emotional',
    title: 'How is your emotional health today, overall?',
    buttonText: 'Next',
    cardImage: (inputValue: number) => (
      <EmotionalHealth inputValue={inputValue} />
    ),
    cardBackground: (inputValue: number) => {
      if (inputValue <= 3) return `linear-gradient(#34456A, #cad0de 90%)`;
      if (inputValue >= 7) return `linear-gradient(#3baffc, #cad0de 90%)`;

      return `linear-gradient(#8693ae, #cad0de 90%)`;
    },
  },
  {
    type: 'spiritual',
    title: 'How is your spirit today, overall?',
    buttonText: 'Next',
    cardImage: (inputValue: number) => (
      <SpiritualHealth inputValue={inputValue} />
    ),
  },
  {
    type: 'hope',
    title: 'How is your hope today, overall?',
    buttonText: 'Send',
    cardImage: () => <div css={{ gridArea: 'image' }} />,
    cardBackground: (inputValue: number) => {
      // given images in a range of 1-5 and an input value of 1-10, we need to
      //  map the input value to the image range
      const imageNum = inputValue === 0 ? 1 : Math.ceil(inputValue / 2);
      return `url(/images/hope-check/hope-${imageNum}.jpg)`;
    },
  },
];
