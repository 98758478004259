import { variants } from '@web/features/theme';
import { useEffect, useState } from 'react';

type Size = {
  width: number | undefined;
  height: number | undefined;
};

const mobileBreakpoint = parseFloat(variants.space['3xl']) * 16;

/**
 * # useWindowSize
 *
 * @see https://usehooks.com/useWindowSize/
 * @export
 * @return {width, height, isMobile, isMobileFromWidth} {@link Size}
 */
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  const isWidthAvailable = 'width' in windowSize;

  const isMobile =
    isWidthAvailable &&
    typeof windowSize.width === 'number' &&
    windowSize.width < mobileBreakpoint;

  const isMobileFromWidth = (width: number) => {
    return isWidthAvailable && typeof windowSize.width === 'number'
      ? width < windowSize?.width
      : true;
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return { ...windowSize, isMobile, isMobileFromWidth };
}
