import { css, SerializedStyles } from '@emotion/react';
import {
  Ph1,
  Ph10,
  Ph3,
  Ph5,
  Ph7,
  SHWoman,
  SvgCloudy,
  SvgRain,
  SvgSunny,
  SvgThunderBolt,
} from '@web/components/svgs/wellness-icons';
import { easing, theme } from '@web/features/theme';
import * as React from 'react';

export function WellnessImageGrid({
  children,
  styles,
}: {
  children: React.ReactNode;
  styles?: SerializedStyles;
}) {
  return (
    <div
      css={css`
        position: relative;
        height: ${theme.space.sm};
        z-index: ${theme.zIndices.base};
        margin: 0 auto;

        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          'top-left top-center top-right'
          'center-left center-center center-right'
          'bottom-left bottom-center bottom-right';

        ${styles}

        svg {
          max-height: 100%;
          max-width: 100%;
          transition: transform 150ms ease-out, opacity 150ms ease-in;
        }
      `}
    >
      {children}
    </div>
  );
}

export function EmotionalHealth({ inputValue }: { inputValue: number }) {
  return (
    <WellnessImageGrid>
      <SvgCloudy
        css={{
          gridArea: 'center-center',
          alignSelf: 'end',
          zIndex: 5,
          opacity: inputValue < 9 ? (9 - inputValue) * 0.7 : 0,
          transform:
            inputValue >= 5
              ? `translate(${inputValue * 6}%, ${inputValue * 3}%)`
              : `translate(${inputValue * 3}%, ${inputValue * 6}%)`,
        }}
      />

      <SvgSunny
        css={{
          gridArea: 'center-center',
          alignSelf: 'end',
          zIndex: 3,
          opacity: inputValue > 6 ? (inputValue - 5) * 0.4 : 0,
          transform:
            inputValue > 6
              ? `translate(
                  ${inputValue * 2}%,
                  -${inputValue}%
                )`
              : 'translateY(0)',
        }}
      />

      <SvgRain
        css={{
          gridArea: 'bottom-center',
          alignSelf: 'start',
          height: '5rem',
          opacity: inputValue < 5 ? (5 - inputValue) * 0.7 : 0,
          transform:
            inputValue < 5
              ? `translateY(-${inputValue * 5}%)`
              : 'translateY(0)',
        }}
      />

      <SvgThunderBolt
        css={{
          gridArea: 'bottom-center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          height: '10rem',
          opacity: inputValue < 3 ? (3 - inputValue) * 0.5 : 0,
          transform:
            inputValue < 3
              ? `translateY(-${inputValue * 5}%)`
              : 'translateY(0)',
        }}
      />
    </WellnessImageGrid>
  );
}

export function PhysicalHealth({ inputValue }: { inputValue: number }) {
  return (
    <WellnessImageGrid
      styles={css`
        padding-top: ${theme.space['24']};

        &:after {
          // shadow
          content: '';
          position: absolute;
          bottom: -${theme.space['2']};
          left: 0;
          right: 0;
          width: 100%;
          height: ${theme.space[24]};
          border-radius: 100%;
          background: radial-gradient(
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.2) ${(9 - inputValue) * 1}%,
            rgba(255, 99, 0, 0) ${(9 - inputValue) * 4}%
          );
          transition: background 150ms cubic-bezier(${easing});
        }

        svg {
          grid-area: center-center;
          height: ${theme.space[64]};
          max-width: ${theme.space[40]};
        }
      `}
    >
      {inputValue < 3 && <Ph1 />}

      {inputValue >= 3 && inputValue < 5 && <Ph3 />}

      {inputValue === 5 && <Ph5 />}

      {inputValue > 5 && inputValue <= 7 && <Ph7 />}

      {inputValue > 7 && <Ph10 />}
    </WellnessImageGrid>
  );
}

export function SpiritualHealth({ inputValue }: { inputValue: number }) {
  return (
    <WellnessImageGrid
      styles={css`
        &:before {
          // aura
          content: '';
          position: absolute;
          top: 15%;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          border-radius: 100%;
          opacity: ${inputValue / 10};
          background: ${`radial-gradient(
            #8829e6,
            rgba(168, 41, 230, ${inputValue / 5}) 30%,
            rgba(168, 41, 230, 0) 70%
          )`};
          height: 100%;
          width: 100%;
          transform: translateY(${-inputValue * 10}px);
          transition: transform 150ms cubic-bezier(${easing}),
            background 150ms cubic-bezier(${easing}),
            opacity 150ms cubic-bezier(${easing});
        }

        &:after {
          // shadow
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: ${theme.space[24]};
          border-radius: 100%;
          background: radial-gradient(
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.2) ${(9 - inputValue) * 1}%,
            rgba(255, 99, 0, 0) ${(9 - inputValue) * 4}%
          );
          transition: background 150ms cubic-bezier(${easing});
        }

        svg {
          position: relative;
          grid-area: center-center;
          z-index: 5;
          max-height: 100%;
          max-width: 100%;
          height: ${theme.space[64]};
          margin: ${theme.space[12]} auto ${theme.space[12]};

          transform: translateY(${-inputValue * 5}px);
          transition: transform 150ms cubic-bezier(${easing}),
            background 150ms cubic-bezier(${easing});
        }
      `}
    >
      <SHWoman />
    </WellnessImageGrid>
  );
}
