import * as React from 'react';

export const SvgCloudy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 256.007 159.269" {...props}>
    <path
      id="cloudy_svg__cloudy_svg__Cloudy"
      fill="#fafaff"
      d="M207.436 62.52a48.511 48.511 0 00-9.618.962 39.664 39.664 0 00-55.551-28.2 52.943 52.943 0 10-99.529 36.134 44.133 44.133 0 001.326 88.245h163.372a48.57 48.57 0 100-97.14zm0 0"
    />
  </svg>
);
