import { css } from '@emotion/react';
import { styles as formStyles } from '@web/components/form/form.styles';
import { easing, theme } from '@web/features/theme';
import { wellnessSteps } from './wellness-steps';

export const styles = {
  cardContainer: (
    currentStep: typeof wellnessSteps[0],
    currentInputValue: number,
  ) => css`
    padding: 10% 5%;
    margin: 0 auto;
    min-width: 100vw;
    min-height: 100vh;

    display: grid;
    grid-template-rows: auto minmax(50vh, 2fr) auto auto;
    grid-template-areas:
      'title'
      'image'
      'form'
      'submit'
      'pagination';
    align-items: center;
    justify-content: center;
    justify-items: center;

    border-radius: ${theme.radii.md};
    background: ${typeof currentStep.cardBackground === 'function'
      ? currentStep.cardBackground(currentInputValue)
      : `url('/images/wellness-bg.svg'),
          ${
            // eslint-disable-next-line no-nested-ternary
            currentInputValue < 3
              ? '#ABC8D5'
              : currentInputValue < 7
              ? '#97C8DE'
              : '#76C8EB'
          }`};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: background 150ms cubic-bezier(${easing.join(',')});
  `,

  cardTitle: css`
    position: relative;
    grid-area: title;
    align-self: start;
    padding: ${theme.space[4]};
    z-index: 0;

    text-align: center;
    font-size: ${theme.fontSizes['2xl']};
    font-weight: ${theme.fontWeights.light};
    color: ${theme.colors.whiteAlpha[900]};

    background: ${theme.colors.blackAlpha[300]};
    border-radius: ${theme.radii.md};
    text-shadow: ${theme.shadows.lg};

    @supports (backdrop-filter: blur(4px)) {
      backdrop-filter: blur(2px);
    }
  `,

  cardSlider: css`
    ${formStyles.formWrapper}
    margin: 0;
    grid-area: form;
    width: 75%;

    [type='range'] {
      margin: 0;
      padding: 0;
      width: 100%;
      height: ${theme.space[4]};
      background: transparent;
      box-shadow: none;
      margin: ${theme.space[12]} auto;
    }

    [type='range']:focus {
      outline: none;
    }

    [type='range'],
    [type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    [type='range']::-webkit-slider-runnable-track {
      box-sizing: border-box;
      border: none;
      width: 100%;
      height: 0.25em;
      background: ${theme.colors.whiteAlpha[900]};
    }

    [type='range']::-moz-range-track {
      box-sizing: border-box;
      border: none;
      width: 100%;
      height: 0.25em;
      background: ${theme.colors.whiteAlpha[900]};
    }
    [type='range']::-ms-track {
      box-sizing: border-box;
      border: none;
      width: 100%;
      height: 0.25em;
      background: ${theme.colors.whiteAlpha[900]};
    }

    [type='range']::-ms-fill-lower {
      height: 0.25em;
      background: #95a;
    }
    [type='range']::-webkit-slider-thumb {
      cursor: pointer;
      pointer-events: auto;
      margin-top: -24px;
      box-sizing: border-box;
      border: none;
      width: ${theme.space[12]};
      height: ${theme.space[12]};
      filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.439));
      border-radius: 50%;
      background: url('/images/slider-knob.svg');
      background-position: center;
      background-size: cover;
    }
    [type='range']::-moz-range-thumb {
      cursor: pointer;
      pointer-events: auto;
      box-sizing: border-box;
      border: none;
      width: ${theme.space[12]};
      height: ${theme.space[12]};
      filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.439));
      border-radius: 50%;
      background: url('/images/slider-knob.svg');
      background-position: center;
      background-size: cover;
    }
    [type='range']::-ms-thumb {
      cursor: pointer;
      pointer-events: auto;
      margin-top: 0;
      box-sizing: border-box;
      border: none;
      width: ${theme.space[12]};
      height: ${theme.space[12]};
      filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.439));
      border-radius: 50%;
      background: url('/images/slider-knob.svg');
      background-position: center;
      background-size: cover;
    }
    [type='range']::-ms-tooltip {
      display: none;
    }
  `,
};
