import { isProduction } from '@core/utils/env';
import { css } from '@emotion/react';
import * as Sentry from '@sentry/nextjs';
import { Button } from '@web/components/button';
import { slideInLeft, theme } from '@web/features/theme';
import type firebase from 'firebase';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import * as React from 'react';

export type ErrorWrapperProps = {
  title?: string;
  error?:
    | Error
    | firebase.FirebaseError
    | { message: string | React.ReactNode }
    | null;
  clearError?: (...args: unknown[]) => unknown;
};

/**
 * # ErrorWrapper
 *
 * Compenent to display when an error occurs.
 *
 * @remarks
 * Displays a message and a title
 * Also, allows a user to refresh or clear an error
 *
 * @export
 * @param {ErrorWrapperProps} {
 *   title = 'Uh Oh!',
 *   error,
 *   clearError,
 * }
 * @return {JSX.Element}
 */
export function ErrorWrapper({
  title = 'Uh Oh!',
  error,
  clearError,
}: ErrorWrapperProps) {
  const router = useRouter();

  if (isProduction()) {
    Sentry.captureException(error);
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return error ? (
    <motion.section variants={slideInLeft} css={styles.container}>
      <h2>{title}</h2>

      {'code' in error && <pre css={styles.code}>Code: {error.code} </pre>}

      <p css={styles.message}>{error.message}</p>

      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          onClick={() => router.reload()}
          className="block warning"
          css={{ margin: '1rem auto' }}
        >
          Refresh
        </Button>

        {clearError && (
          <Button
            onClick={clearError}
            className="block info"
            css={{ margin: '1rem auto' }}
          >
            Clear
          </Button>
        )}
      </div>
    </motion.section>
  ) : null;
}

const styles = {
  container: css`
    max-width: ${theme.space['2xl']};
    border-radius: ${theme.radii.lg};
    margin: ${theme.space[4]} auto;
    padding: ${theme.space[8]} ${theme.space[8]};
    box-shadow: ${theme.shadows['2xl']};
    background-color: ${theme.colors.blackAlpha[300]};
  `,

  code: css`
    font-size: ${theme.fontSizes.sm};
    font-family: ${theme.fonts.mono};
  `,

  message: css`
    margin: 2rem auto;
    word-wrap: break-word;
  `,
};
